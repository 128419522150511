<template>
  <div class="store_register">
    <div class="title_bg">
      <div class="title">商超注册</div>
    </div>

    <div class="formbox">
      <div class="form">
        <el-form ref="ruleForm" :model="ruleForm" status-icon :rules="rules" label-width="150px" label-position="left">
          <el-form-item label="营业执照" prop="businessLicense">
            <el-upload class="upload-demo" accept=".png,.jpeg,.jpg" :file-list="fileList" :limit="1" name="multipartFile" :action="action" :on-success="busiLicenseSuccess" :before-upload="beforeUploads" :on-remove="handleRemove" list-type="picture">
              <el-button type="primary" plain>点击上传</el-button>
              <div slot="tip" class="el_upload__tip">
                <i class="el-icon-warning"></i>
                <span>请确保营业执照信息清晰可见，图片格式仅支持jpg、jpeg、png，图片大小不超过5MB。</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="LOGO">
            <el-upload class="upload-demo" accept=".png,.jpeg,.jpg" :file-list="logoFileList" :limit="1" name="multipartFile" :action="action" :on-success="logoUploadSuccess" :before-upload="beforeUploads" :on-remove="handleRemoveLogo" list-type="picture">
              <el-button type="primary" plain>点击上传</el-button>
              <div slot="tip" class="el_upload__tip">
                <i class="el-icon-warning"></i>
                <span>LOGO图片格式仅支持jpg、jpeg、png，图片大小不超过5MB。</span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model.trim="ruleForm.companyName" placeholder="上传营业执照自动识别" disabled></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="creditCode">
            <el-input v-model="ruleForm.creditCode" placeholder="上传营业执照自动识别" disabled></el-input>
          </el-form-item>
          <el-form-item label="店铺名称" prop="shopName">
            <el-input v-model.trim="ruleForm.shopName" placeholder="请输入店铺名称"></el-input>
          </el-form-item>
          <el-form-item label="关联学校" prop="schoolId">
            <el-select v-model="ruleForm.schoolId" filterable placeholder="请选择关联学校，可搜索" style="width: 100%" @change="schoolChange">
              <el-option v-for="(item, index) in schoolList" :key="index" :label="item.schoolName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人" prop="contactMan">
            <el-input v-model="ruleForm.contactMan" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contactTel">
            <el-input v-model.number="ruleForm.contactTel" type="number" placeholder="请输入手机号码（该手机号码为登录账号）"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input v-model="ruleForm.password" placeholder="请输入登录密码，密码至少8位数且必须包含大写字母、小写字母、数字"></el-input>
            <el-tooltip content="密码至少8位数且必须包含大写字母、小写字母、数字" placement="top" effect="light">
              <i class="el-icon-warning-outline tips"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="ruleForm.confirmPassword" placeholder="请再次输入登录密码"></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="selectedOptions">
            <el-cascader ref="cascader" v-model="ruleForm.selectedOptions" :options="options" :props="regionParams" placeholder="请选择地区" style="width: 100%" @change="addressChoose"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="商超简介" prop="introduction">
            <vue-word-editor v-model="ruleForm.introduction" :config="config" ref="wordeditor"></vue-word-editor>
          </el-form-item>
          <div class="sumbit">
            <el-button type="" @click="$router.go(-1)">去登录</el-button>
            <el-button type="primary" @click="handleSumbit('ruleForm')">注册</el-button>
          </div>
        </el-form>
      </div>
    </div>

    <footers />
  </div>
</template>

<script>
import VueWordEditor from "vue-word-editor"
import "quill/dist/quill.snow.css"
// 表单初始化
function createdForm() {
  return {
    companyName: "", // 公司名称
    shopName: "", // 店铺名称
    schoolId: "", // 学校id
    schoolName: "", // 学校name
    introduction: "", // 简介
    selectedOptions: [], // 选择的地区
    address: "", // 详细地址
    contactMan: "", // 联系人
    contactTel: "", // 联系电话
    businessLicense: "", // 营业执照id
    busiLicenseUrl: "", // 营业执照url
    logoId: "", // logo id
    logoUrl: "", // logo url
    creditCode: "", // 统一社会信用代码
    password: "", // 密码
    confirmPassword: "", // 确认密码
    provinceCode: "",
    province: "",
    cityCode: "",
    city: "",
    areaCode: "",
    area: "",
  }
}
export default {
  components: { VueWordEditor },
  data() {
    return {
      iconLoading: false,
      ruleForm: createdForm(),
      options: require("../assets/pca-code.json"), // 省市区数据(三维数组)
      schoolList: [],
      regionParams: { label: "name", value: "code" },
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      fileList: [],
      logoFileList: [],
      rules: {
        companyName: [{ required: true, message: "缺少公司名称", trigger: "blur" }],
        shopName: [{ required: true, message: "缺少店铺名称", trigger: "blur" }],
        schoolId: [{ required: true, message: "缺少关联学校", trigger: "blur" }],
        selectedOptions: [{ required: true, message: "缺少所在地区", trigger: "change" }],
        address: [{ required: true, message: "缺少详细地址", trigger: "blur" }],
        introduction: [{ required: true, message: "缺少商超简介", trigger: "submit" }],
        contactMan: [{ required: true, message: "缺少联系人", trigger: "blur" }],
        businessLicense: [{ required: true, message: "缺少营业执照", trigger: "blur" }],
        creditCode: [{ required: true, message: "缺少统一社会信用代码", trigger: "blur" }],
        contactTel: [
          { required: true, message: "请输入电话号码（手机号码是登录账号）", trigger: "blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号码格式不正确", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              // 只有在值不为空时才发起请求验证手机号是否已存在
              if (value) {
                // 快速正则匹配手机号格式
                // 校验手机号是否已被注册
                this.iconLoading = true
                this.$axios
                  .get(`${this.$api.shopInfoContactTel}/${value}`)
                  .then(res => {
                    // 如果响应-1 表示该手机号已存在
                    const { code, desc } = res.data
                    if (code == -1 || code == 900) {
                      callback(new Error(desc))
                    } else {
                      callback() // 手机号可以使用，通过校验
                    }
                  })
                  .catch(error => {
                    this.iconLoading = false
                    console.error("校验手机号是否存在时发生错误:", error)
                    callback() // 出错时也可以先通过校验，后续处理错误或给用户友好提示
                  })
              } else {
                callback() // 手机号格式不正确，不过这里仍通过校验，因为前面应该已经有关于手机号格式的规则校验
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 8,
            message: "密码格式不正确",
            validator: (rule, value, callback) => {
              if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value)) {
                callback(new Error("密码必须包含大写字母、小写字母、数字"))
              } else {
                callback()
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        confirmPassword: [
          { required: true, message: "请再次输入登录密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value !== this.ruleForm.password) {
                callback(new Error("两次输入的密码不一致!"))
              } else {
                callback()
              }
            },
            trigger: "blur",
          },
        ],
      },
      config: {
        placeholder: "请输入内容",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            ["link", "image"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
          ],
        },
        // 上传图片的配置
        uploadImage: {
          url: this.$axios.defaults.baseURL + this.$api.uploadFile,
          name: "multipartFile",
          // res是结果，insert方法会把内容注入到编辑器中，res.data.url是资源地址
          uploadSuccess: (res, insert) => {
            if (res.data.code !== 100) {
              this.$message.error("请上传图片格式，如JPG，JPEG，PNG文件格式")
            } else {
              insert(res.data.result.fileUrl)
            }
          },
          showProgress: false,
        },
      },
    }
  },
  created() {
    this.getSchoolList()
  },
  methods: {
    // 学校列表
    getSchoolList() {
      this.$axios
        .get(this.$api.schoolList, {
          params: {
            page: 1,
            pageSize: 9999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.schoolList = res.data.result.list
          }
        })
    },
    // 选择学校
    schoolChange(e) {
      this.ruleForm.schoolId = e
      this.ruleForm.schoolName = this.schoolList.filter(item => {
        return item.id == e
      })[0].schoolName
    },
    // 选择省市区
    addressChoose() {
      this.ruleForm.provinceCode = this.$refs["cascader"].getCheckedNodes()[0].path[0]
      this.ruleForm.cityCode = this.$refs["cascader"].getCheckedNodes()[0].path[1]
      this.ruleForm.areaCode = this.$refs["cascader"].getCheckedNodes()[0].path[2]
      this.ruleForm.province = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[0]
      this.ruleForm.city = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[1]
      this.ruleForm.area = this.$refs["cascader"].getCheckedNodes()[0].pathLabels[2]
    },
    supplierregister() {
      this.$axios.post(this.$api.shopInfoRegister, this.ruleForm).then(res => {
        if (res.data.code == 100) {
          this.$message.success("注册成功")
          // this.ruleForm.introduction = ''
          // this.ruleForm = Object.assign({}, createdForm());
          // this.$refs.ruleForm.resetFields();
          // this.fileList = [];
          // this.logoFileList = [];
          setTimeout(() => {
            this.$router.replace("login")
          }, 1000)
        } else {
          this.$message({ showClose: true, message: res.data.desc, type: "error" })
        }
      })
    },
    handleSumbit(ruleForm) {
      this.ruleForm.introduction = this.$refs.wordeditor.editor.root.innerHTML
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.$axios.get(`${this.$api.shopInfoShopName}/${this.ruleForm.shopName}`).then(res => {
            if (res.data.code == 100) {
              this.supplierregister()
            } else {
              this.$message({ showClose: true, message: res.data.desc, type: "error" })
            }
          })
        } else {
          return false
        }
      })
    },
    // logo上传成功
    logoUploadSuccess(res) {
      if (res.code == 100) {
        this.ruleForm.logoId = res.result.fileId
        this.ruleForm.logoUrl = res.result.fileUrl
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // 删除logo
    handleRemoveLogo() {
      this.ruleForm.logoId = ""
      this.ruleForm.logoUrl = ""
    },
    // 删除营业执照
    handleRemove() {
      this.ruleForm.businessLicense = ""
      this.ruleForm.creditCode = ""
      this.ruleForm.companyName = ""
    },
    // 营业执照上传成功
    busiLicenseSuccess(res) {
      if (res.code == 100) {
        this.ruleForm.businessLicense = res.result.fileId
        this.ruleForm.busiLicenseUrl = res.result.fileUrl
        this.busiLicenseOCR(this.ruleForm)
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    // OCR识别营业执照自动填充
    busiLicenseOCR(e) {
      if (e.busiLicenseUrl) {
        this.$axios
          .get(this.$api.getBizLicenseInfoOCR, {
            params: {
              imgUrl: e.busiLicenseUrl,
            },
          })
          .then(res => {
            if (res.data.code == 100) {
              this.ruleForm.companyName = res.data.result.name
              this.ruleForm.creditCode = res.data.result.regNum
            } else {
              this.handleRemove()
            }
          })
      }
    },
    // 文件上传
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || "image/jpg" || "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
  },
}
</script>
<style lang="scss" scoped>
.store_register {
  padding-bottom: 30px;

  .title_bg {
    position: relative;
    width: 100%;
    height: 130px;
    background: url(../assets/img/head.png) no-repeat;
    background-size: cover;
    background-position: 80%;
    box-sizing: border-box;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 130px;
      color: #fff;
      font-size: 28px;
      font-family: "zzgf";
      text-align: center;
    }
  }

  .formbox {
    display: flex;
    justify-content: center;
    width: 800px;
    margin: 20px auto;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    padding: 60px 120px;
    background-color: #fff;

    .form {
      min-width: 600px;

      .usable_tel,
      .tips {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
        color: #c0c4cc;
        cursor: pointer;
      }

      .usable_tel {
        .el-icon-error {
          color: #f56c6c;
        }

        .el-icon-success {
          color: #67c23a;
        }
      }

      .tips {
        &:hover {
          opacity: 0.8;
        }
      }
    }

    /deep/.el-input__inner {
      width: 100%;
      border: 1px solid #dcdfe6;
      border-radius: 10px;
      background-color: #fff;

      &:hover {
        border-color: #c0c4cc;
      }

      &:focus {
        border-color: #069bbc;
      }
    }

    .el_upload__tip {
      display: flex;
      align-items: center;
      color: #1a1a1a;

      .el-icon-warning {
        color: #069bbc;
        font-size: 16px;
      }

      span {
        margin-left: 6px;
      }
    }

    .sumbit {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .el-button {
        width: 150px;
      }
    }
  }

  /deep/ .ql-editor {
    min-height: 200px;
    max-height: 500px;
  }
}
</style>
